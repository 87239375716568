.cardsHome {
    @apply flex gap-8 items-center justify-center flex-col lg:flex-row w-screen;
}

.cardsHome .card {
    @apply flex w-full sm:w-3/4 lg:w-1/5 rounded overflow-hidden shadow-lg hover:cursor-pointer hover:bg-lime-200 ;
}

.cardsHome .card a {
    @apply w-full;
}

.cardsHome .card a .card-content {
    @apply px-6 py-4 lg:h-56 flex flex-col justify-between;
}

.cardsHome .card a .card-content .card-content__title {
    @apply mb-2 flex flex-row justify-between items-center;
}

.cardsHome .card a .card-content .card-content__title p {
    @apply font-bold text-sm md:text-base lg:text-lg xl:text-xl;
}

.cardsHome .card a .card-content .card-content__text {
    @apply flex justify-end;
}

.cardsHome .card a .card-content .card-content__text svg {
    @apply bg-black/10 rounded-3xl p-1 w-10 h-10;
}
