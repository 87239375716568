@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.display-none {
    display: none;
}

body {
    font-family: "Poppins";
    font-size: 16px;
}

.navbar {
    @apply py-4 fixed top-0 left-0 right-0 flex flex-row items-center justify-between px-4 shadow-xl z-10 bg-white;
}

.navbar img {
    @apply w-auto md:w-1/2 lg:w-3/4;
}

.menu {
    @apply flex-row items-center justify-end hidden lg:flex;
}

.menu-burguer {
    @apply flex lg:hidden;
}

.menu li {
    @apply px-2;
}

.menu li a {
    @apply hover:text-sky-700 px-4 py-2 flex lg:text-sm xl:text-base;
}

.menu li a span {
    @apply inline-flex justify-center items-center mr-2;
}

.menu li a.active {
    @apply text-white bg-sky-700;
}

.menu li a.active svg {
    @apply fill-white;
}

main {
    @apply py-20 px-4 mt-14 md:mt-4;
}

footer {
    @apply justify-center items-center fixed bottom-0 left-0 right-0 flex flex-col border-t-2 border-black z-10 bg-white;
}

footer div {
    @apply text-xs py-4;
}

hr {
    @apply border-t-2 border-gray-400;
}

.component__title {
    @apply text-4xl text-black font-bold;
}

.component__description {
    @apply bg-blue-100 border-t border-b border-blue-500 text-blue-700 p-4 my-4;
}

.section__selectors {
    @apply my-5 grid gap-2 sm:grid-cols-2 xl:grid-cols-3;
}


#texttool-content h1 {
    @apply text-4xl text-black font-bold;
}

#texttool-content h2, .legaltext_content h2 {
    @apply text-3xl text-black font-bold mt-5;
}

#texttool-content h3 {
    @apply text-2xl text-black font-bold mt-5;
}

#texttool-content h4 {
    @apply text-xl text-black font-bold mt-5;
}

#texttool-content h5 {
    @apply text-lg text-black font-bold mt-5;
}

.group-list {
    @apply my-5 bg-gray-100 p-5 border-l-4 border-gray-300 xl:w-1/2 sm:w-auto;
}

.flex_content {
    @apply flex flex-col lg:mt-5;
}