.section__selectors--group {
    @apply flex flex-col;
}

label {
    @apply pb-1;
}

.section__selectors--input {
    @apply border-black border-2 py-1 px-2;
}